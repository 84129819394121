.postIMG {
    max-width: 100%;
    margin: auto;
}


html {
    cursor: url(pointer01_small.png), auto;
    background-image: url("CheckerBG.png");
    background-repeat: repeat;
    -moz-box-shadow:    inset 0 0 200px #000000;
    -webkit-box-shadow: inset 0 0 200px #000000;
    box-shadow:         inset 0 0 200px #000000;
}

/* p {
    background: rgb(66, 62, 62);
    background-image: url("darkwood.jpg");
    background-size: 512px 512px;
    color:rgb(255, 255, 255);
    padding: 2%;
    border-radius: 5%;
    margin: 4% 2%;
} */

footer {
    color:rgb(255, 255, 255);
}

/* h1 {
    background: rgb(66, 62, 62);
    background-image: url("darkwood.jpg");
    background-size: 512px 512px;
    color:rgb(255, 255, 255);
    padding: 1%;
    border-radius: 5%;
    margin: 4% 2%;
    max-width: 50%;
} */

.navbox {
    display: flex;
    margin-top: 0px;
	overflow: hidden;
	width: 100%;
    max-height: 16vh;
    background-image: url("wood.jpg");
    background-size: 512px 512px;
    -moz-box-shadow:    inset 0 0 50px #000000;
    -webkit-box-shadow: inset 0 0 50px #000000;
    box-shadow:         inset 0 0 50px #000000;
}


.shadowlink {
    color:rgb(255, 255, 255);
    border-radius: 5%;
    padding: 1%;
    margin: 1%;
    font-size: 30px;
    text-decoration: none;
    text-align-last: center;
    flex: .5 .5 auto;
    -moz-box-shadow:    inset 0 0 50px #000000;
    -webkit-box-shadow: inset 0 0 50px #000000;
    box-shadow:         inset 0 0 50px #000000;
}

.editBox {
    background: rgb(141, 141, 141);
}

.delbutton {
    margin:2000px;
    z-index: 20;
}

