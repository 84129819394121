.loginform {
	position: absolute;
	top: 25%;
	left: 40%;
	padding: 20px;
	background-image: url("wood.jpg");
    background-size: 512px 512px;
    -moz-box-shadow:    inset 0 0 50px #000000;
    -webkit-box-shadow: inset 0 0 50px #000000;
    box-shadow:         inset 0 0 50px #000000;
    color: white;
}

input[type="text"],
input[type="password"] {
	width: 100%;
	padding: 5px;
	margin: 5px 0 22px 0;
	display: inline-block;
	border: none;
	background: #272727;
    color: white;
}

.loginfont {
	text-align: center;
    color: white;
}

.bformtext {
	text-align: center;
    color: white;
}
