
.homeLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2%;
    background: url(bg_blue.png);
    background-size: 10%;
    padding: 1%;
    border-radius: 16px;
    flex-wrap: wrap;
}
.homeLinkBox {
    color:rgb(65, 64, 14);
    background-image: url(talkbox.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 2%;
    font-family: Aclonica, sans-serif;
    color:rgb(0, 0, 0);
    text-align: center;
    font-size: 2em;
    width: 30vw;
    flex-grow: 1;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
}
.homeLinkBox iframe{
    margin-top: 2%;
    margin-bottom: 2%;
}

.blogviewbox {
    display: flex;
    flex-direction: row;
}

.blogpagebox {
    width: 100%;
    max-height: 88vh;
    overflow-y: scroll;
    background: url(bg_purple.png);
    background-size: 100%;
}

.blog-scroll {
    overflow-y:scroll;
    height: 100%;
    max-height: 88vh;
}

.blogTitle{
    color:rgb(65, 64, 14);
    background-image: url(talkbox.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 2%;
    flex-grow: 1;
    font-family: Aclonica, sans-serif;
    color:rgb(0, 0, 0);
    text-align: center;
    font-size: 2em;
}

.blogP {
    background-image: repeating-linear-gradient(rgb(113, 51, 126), rgb(49, 21, 61));
    background-size: 100%;
    padding: 4% 4% 4% 4%;
    flex-grow: 1;
    margin-left: 1%;
    font-family: Archivo Narrow, sans-serif;
    color:rgb(255, 255, 255);
    border-radius: 64px;
    border-color:rgb(255, 255, 255);
    border-width: 10px;
    border-style: double;
    font-size: 1.2em;
}

.VidBackground{
    background-color:rgb(0, 0, 0, .5);
    border-radius: 64px;
    background-size: 100% 100%;
    padding: 4% 4% 4% 4%;
    flex-grow: 1;
    margin-left: 1%;
}
.wrapper-wrapper {
    margin:auto;
    width:50%;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 10%;
    
}

.vid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-color:rgb(0, 0, 0);
    border-style: solid;
    border-width: 20px;;
    border-radius: 16px;
}

.blogbox-big {
    display:flex;
    flex-direction: column;
    background: rgb(0, 0, 0);
    padding: 1%;
    max-height: 100vh;
}

.blogli {
    color:rgb(65, 64, 14);
    background-image: url(talkbox.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 4% 4% 4% 4%;
    flex-grow: 1;
    margin-left: 1%;
}

.blogli-alt {
    color:rgb(65, 64, 14);
    background-image: url(talkbox2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 4% 4% 4% 4%;
    flex-grow: 1;
    margin-left: 1%;
}

.blogli .bloglink {
    font-family: Aclonica, sans-serif;
    color:rgb(255, 253, 147);
    text-decoration: none;
}

.blogli-alt .bloglink {
    font-family: Aclonica, sans-serif;
    color:rgb(255, 253, 147);
    text-decoration: none;
}

.blogitembox {
    display: flex;
    flex-direction: row;
    margin: 2%;
    background: url(bg_blue.png);
    background-size: 25%;
    padding: 4%;
    border-radius: 16px;
}

.blogdecorator {
    align-self: flex-start;
    margin-right: 2%;
}


.blogdate {
    font-family: "Aclonica", sans-serif;
    font-size: 1em;
}

.blogbox-title {
    justify-content: center;
    background-image: url(talkbox.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color:rgb(41, 43, 15);
    text-align:center;
    font-family: "Aclonica", sans-serif;
    font-size: 2em;
    padding: 2%;
}


.mainwindow {
    display:flex;
    flex-direction: row;
    background-color: rgb(0, 0, 0);
    width:auto;
    text-align: center;
}

.NFpic{
    width:25%;
    margin-top: -5%;
    margin-right: 25%;
    margin-left: 25%;
}

.centerbox {
    display: inline-block;
    color:white;
    width:100%;
    text-align: center;
}

.centerbox img{
    width: 70%;
    margin: -10%;
    
}

.twitterbutton{
    width:100%;
    border-radius: 8%;
}


.navboxhome {
    display: flex;
    margin-top: 0px;
	overflow: hidden;
	width: 100%;
    max-height: 16vh;
    background-image: url("wood.jpg");
    background-size: 512px 512px;
    -moz-box-shadow:    inset 0 0 50px #000000;
    -webkit-box-shadow: inset 0 0 50px #000000;
    box-shadow:         inset 0 0 50px #000000;
}

.navlink {
    color:rgb(255, 255, 255);
    border-radius: 5%;
    padding: .5%;
    margin: 1%;
    width:4%;
    max-width:64px;
    font-size: 30px;
    text-decoration: none;
    text-align-last: center;
    flex: .01 .01 auto;
    -moz-box-shadow:    inset 0 0 40px #000000;
    -webkit-box-shadow: inset 0 0 40px #000000;
    box-shadow:         inset 0 0 40px #000000;
}

.homelink{
    color:rgb(255, 255, 255);
    border-radius: 5%;
    margin: 1%;
    width: 10%;
}
.logo {
    width: 100%;
    max-width: 128px;
}

.cornerbox{
    width: 50%;
    min-width: 1024px;
    display: flex;
}
